const helperMixins = {
  methods: {
    is_null: (value) => value == null,

    scanObject: function (nestedObj, pathArr, defaultValue = null) {
      if (!this.isArray(pathArr)) {
        pathArr = pathArr.split('.')
      }

      let retrievedValue = pathArr.reduce(
        (obj, key) => (obj && obj[key] !== 'undefined' ? obj[key] : undefined),
        nestedObj
      )
      return !this.is_null(retrievedValue) ? retrievedValue : defaultValue
    },

    isArray: function (value) {
      return Array.isArray(value)
    },
  },
}

export default helperMixins
