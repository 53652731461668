<template>
  <v-app>
    <app-side-bar v-if="showNavs" />
    <app-header-menu v-if="showNavs" />
    <v-main class="accent">
      <router-view />
      <v-snackbar v-model="snackbar.showing" :color="snackbar.color" bottom>
        <div class="tw-flex tw-justify-between tw-items-center">
          <span> {{ snackbar.text }}</span>
          <v-btn depressed text @click="snackbar.showing = false">close</v-btn>
        </div>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
export default {
  components: {
    appSideBar: () => import('@/components/navigation/NavigationDrawer'),
    appHeaderMenu: () => import('@/components/navigation/HeaderMenu'),
  },
  computed: {
    ...mapState(['snackbar']),
    ...mapState({
      isAuthenticated: (state) => state.auth.idToken,
      showNavs() {
        return this.isAuthenticated && !this.$route.meta.noNavigation
      },
    }),
  },
}
</script>
<style lang="scss">
.v-sheet--offset {
  top: -25px;
  position: relative;
}

.v-sheet--offset .round-coners {
  border-radius: 5px;
}
</style>
