/* eslint-disable no-unused-vars */
import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import auth from './modules/auth'
import bidForm from './modules/bid-form'
import garage from './modules/garage'

import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    bidForm,
    garage,
  },
  state: {
    snackbar: {},
    alertError: false,
    alertReset: false,
    loading: false,
    itemId: '',
    loadingTable: false,
    loadingVehicleTable: false,
    loadingText: '',
    users: [],
    vendors: [],
    vehicles: [],
    carRequest: [],
  },
  mutations: {
    AUTH_FAILED(state, userData) {
      state.snackbar = userData.snackbar
      state.loading = userData.loading
    },
    SNACKBAR(state, payload) {
      state.snackbar = {
        showing: !!payload.state,
        text: payload.message,
        color: payload.variant,
      }
    },
    INVITE_RES(state, payload) {
      state.snackbar = payload.snackbar
      state.loading = payload.loading
    },
    FORGOT_PASS_RES(state, payload) {
      state.snackbar = payload.snackbar
      state.loading = payload.loading
    },
    RESET_PASS_RES(state, payload) {
      state.snackbar = payload.snackbar
      state.loading = payload.loading
    },
    RESET_PASS_URL(state, payload) {
      state.itemId = payload
    },
    CREATE_PASS_RES(state, payload) {
      state.snackbar = payload.snackbar
      state.loading = payload.loading
    },
    CREATE_PASS_URL(state, payload) {
      state.itemId = payload
    },
    // CLEAR_AUTH_DATA(state) {
    //   state.idToken = null
    //   state.email = ''
    //   state.username = ''
    //   state.roles = ''
    // },
    STORE_USERS(state, payload) {
      state.users = payload.users
      state.loadingTable = payload.loadingTable
      state.loadingText = payload.loadingText
    },
    STORE_VENDORS(state, payload) {
      state.vendors = payload.vendors
      state.loadingTable = payload.loadingTable
      state.loadingText = payload.loadingText
      state.loadingVehicleTable = payload.loadingVehicleTable
    },
    STORE_CAR_RENTAL_REQUEST(state, payload) {
      state.carRequest = payload.carRequest
      state.loadingTable = payload.loadingTable
      state.loadingText = payload.loadingText
    },
  },
  actions: {
    inviteUser({ commit }, inviteData) {
      const instance = axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL2,
      })
      instance
        .post(`/backoffice/api/admin/invite`, inviteData)
        .then(() => {
          commit('INVITE_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `You have successfully added , ${inviteData.fname} as ${inviteData.role}`,
              color: 'success',
            },
          })
        })
        .catch((err) => {
          commit('INVITE_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${err.message}`,
              color: 'error',
            },
          })
        })
    },
    inviteVendor({ commit }, inviteData) {
      axios
        .post('/backoffice/api/admin/invite-vendor ', inviteData)
        .then(() => {
          commit('INVITE_RES', {
            snackbar: {
              showing: true,
              text: `You have successfully added , ${inviteData.name} as ${inviteData.role}`,
              color: 'success',
            },
          })
        })
        .catch((err) => {
          commit('INVITE_RES', {
            snackbar: {
              showing: true,
              text: `${err.message}`,
              color: 'error',
            },
          })
        })
    },

    forgotPassword({ commit }, resetData) {
      axios
        .post('/backoffice/api/auth/password-reset/email', resetData)
        .then((res) => {
          commit('FORGOT_PASS_RES', {
            snackbar: {
              showing: true,
              text: `${res.data.message}`,
              color: 'success',
            },
          })
          router.replace('/email')
        })
        .catch((err) => {
          commit('FORGOT_PASS_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${err.response.data.error}, ` + `${err.message}`,
              color: 'error',
            },
          })
        })
    },
    createPassword({ commit, state }, formData) {
      axios
        .post('motor-assessment/api/users/confirm', {
          newPassword: formData.password,
          session: state.auth.createUser.Session,
          email: state.auth.createUser.challengeParam.userAttributes.email,
          userId: state.auth.createUser.challengeParam.userAttributes.email,
        })
        .then((res) => {
          commit('CREATE_PASS_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${res.data.msg}`,
              color: 'success',
            },
          })
          router.replace('/dashboard')
        })
        .catch((err) => {
          commit('CREATE_PASS_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${err.response.data.error}, ` + `${err.message}`,
              color: 'error',
            },
          })
        })
    },

    resetPassword({ commit, state }, formData) {
      axios
        .post(
          '/backoffice/api/auth/password-reset/token/' + state.itemId,
          formData
        )
        .then((res) => {
          commit('RESET_PASS_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${res.data.message}`,
              color: 'success',
            },
          })
          router.replace('/')
        })
        .catch((err) => {
          commit('RESET_PASS_RES', {
            loading: false,
            snackbar: {
              showing: true,
              text: `${err.response.data.error}, ` + `${err.message}`,
              color: 'error',
            },
          })
        })
    },

    fetchUser({ commit }) {
      axios
        .get('/backoffice/api/admin/users')
        .then((res) => {
          const users = res.data.users
          commit('STORE_USERS', {
            users,
            loadingTable: false,
          })
        })
        .catch((err) => {
          commit('STORE_USERS', {
            loadingTable: false,
            loadingText: `${err.message}`,
          })
          commit(
            'SNACKBAR',
            {
              state: 'failed',
              message: `${err.message}`,
              variant: 'error',
            },
            { root: true }
          )
        })
    },
    fetchVendors({ commit }) {
      axios
        .get('/backoffice/api/admin/vendors-vehicles')
        .then((res) => {
          const vendordata = res.data.vendors
          const vendors = []
          for (let key in vendordata) {
            const vendor = vendordata[key]
            vendor.id = key
            vendors.push(vendor)
          }

          commit('STORE_VENDORS', {
            vendors,
            loadingTable: false,
            loadingVehicleTable: false,
          })
        })
        .catch((err) => {
          commit('STORE_VENDORS', {
            loadingTable: false,
            loadingText: `${err.message}`,
          })
          commit(
            'SNACKBAR',
            {
              state: 'failed',
              message: `${err.message}`,
              variant: 'error',
            },
            { root: true }
          )
        })
    },

    fetchRequest({ commit }) {
      axios
        .get('/backoffice/api/admin/rental-requests')
        .then((res) => {
          const carRequest = res.data.carRentals
          commit('STORE_CAR_RENTAL_REQUEST', {
            carRequest,
            loadingTable: false,
          })
        })
        .catch((err) => {
          commit('STORE_CAR_RENTAL_REQUEST', {
            loadingTable: false,
            loadingText: `${err.message}`,
          })
          commit(
            'SNACKBAR',
            {
              state: 'failed',
              message: `${err.message}`,
              variant: 'error',
            },
            { root: true }
          )
        })
    },
  },

  getters: {
    username(state) {
      return state.username
    },
    isAuthenticated(state) {
      return state.idToken !== null
    },
    loadingText(state) {
      return state.loadingText
    },
    alertError(state) {
      return state.alertError !== true
    },
    snackbackResponse(state) {
      return state.snackbackResponse
    },
    resetPasswordResponse(state) {
      return state.message
    },
  },
})
