/* eslint-disable no-console */

import { register } from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log(
      //   'App is being served from cache by a service worker.\n'
      //   + 'For more details, visit https://goo.gl/AFskqB',
      // );
    },
    registered() {
      // console.log('Service worker has been registered.');
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated(registration) {
      // eslint-disable-next-line no-alert,no-restricted-globals
      const confirmationResult = confirm('New update Available! Update App?')
      if (confirmationResult)
        registration.waiting.postMessage({ action: 'skipWaiting' })
    },
    offline() {
      // console.log('No internet connection found. App is running in offline mode.');
    },
    // eslint-disable-next-line no-unused-vars
    error(error) {
      // console.error('Error during service worker registration:', error);
    },
  })

  if ('serviceWorker' in navigator) {
    let refreshing
    // eslint-disable-next-line no-unused-vars
    navigator.serviceWorker.addEventListener('controllerchange', (e) => {
      if (refreshing) return
      window.location.reload()
      refreshing = true
    })
  }
}
