import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import Connection from '@/views/errors/Connection'
import auth from '@/router/middleware/auth'
import guest from '@/router/middleware/guest'
import middlewarePipeline from '@/router/middlewarePipeline'
import { viewPermissions } from '@/utils/roles'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: () => import('@/auth/SignIn'),
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: () => import('@/auth/ForgotPassword'),
    meta: {
      middleware: [guest],
    },
  },

  {
    path: '/createpassword',
    name: 'CreatePassword',
    component: () => import('@/auth/CreatePassword'),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/resetpassword',
    name: 'ResetPassword',
    component: () => import('@/auth/ResetPassword'),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard/Dashboard'),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/dashboard/report',
    name: 'DashboardReports',
    component: () => import('@/views/dashboard/Reports'),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: '/management',
    name: 'Management',
    component: () => import('@/views/team-management/TeamMenu'),
    meta: {
      // if this is just one role, passing one is PK, when more, use roles meta, see bellow
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/team-management',
    name: 'TeamTable',
    component: () => import('@/views/team-management/TeamManagment'),
    meta: {
      // if this is just one role, passing one is PK, when more, use roles meta, see bellow
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/approver-management',
    name: 'ApproverManagement',
    component: () => import('@/views/team-management/ApproverManagement'),
    meta: {
      middleware: [auth],
      roles: ['admin'],
    },
  },
  {
    path: '/tat-management',
    name: 'TATManagement',
    component: () => import('@/views/team-management/TATManagement'),
    meta: {
      middleware: [auth],
      roles: ['admin'],
    },
  },
  {
    path: '/tat-reports',
    name: 'TATReports',
    component: () => import('@/views/TATReports'),
    meta: {
      middleware: [auth],
      roles: [
        'admin',
        'claimOfficer',
        'internalAssessor',
        'sectionHead',
        'technician',
      ],
    },
  },
  {
    path: '/service-providers',
    name: 'ServiceProvider',
    component: () => import('@/views/service-provider/VendorMenu'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/regions',
    name: 'RegionManagement',
    component: () => import('@/views/RegionManagement'),
    meta: {
      // if this is just one role, passing one is PK, when more, use roles meta, see bellow
      middleware: [auth],
      roles: ['admin'],
    },
  },
  {
    path: '/service-providers/car-rental/vendor',
    name: 'CarRentalVendor',
    component: () => import('@/views/service-provider/VendorManagment'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/service-providers/car-rental/request',
    name: 'CarRental',
    component: () =>
      import('@/components/vendor-managment/car-rental/CarRentalRequestTable'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/service-providers/car-rental/vehicle',
    name: 'VehicleTable',
    component: () => import('@/components/vendor-managment/VehicleTable'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/service-providers/garage',
    name: 'GarageTable',
    component: () => import('@/views/service-provider/GarageTable'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor'],
    },
  },
  {
    path: '/assessment-center',
    name: 'AssessmentCenter',
    component: () => import('@/views/assessment/AssessmentCentre'),
    meta: {
      middleware: [auth],
      // when roles are more than one apart from auth, use this
      roles: [
        'internalAssessor',
        'externalAssessor',
        'technician',
        'claimOfficer',
      ],
    },
  },
  {
    path: '/book-vehicle',
    name: 'BookVehicle',
    component: () => import('@/views/assessment/VehicleBooking'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician'],
    },
  },
  {
    path: '/major-accident',
    name: 'MajorAccident',
    component: () => import('@/views/assessment/major-accident/MajorAccident'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'externalAssessor'],
    },
  },
  {
    path: '/major-accident/bid-form/:bookingId',
    name: 'BidFormPreparation',
    component: () =>
      import('@/views/assessment/major-accident/BidFormPreparation'),
    meta: {
      middleware: [auth],
      roles: [
        'internalAssessor',
        'technician',
        'externalAssessor',
        'claimOfficer',
      ],
    },
  },
  {
    path: '/bids-submitted',
    name: 'BidsSubmitted',
    component: () => import('@/views/assessment/bid-submitted/BidSubmitted'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician'],
    },
  },
  {
    path: '/bids-submitted/qualified-bid',
    name: 'QualifiedBids',
    component: () => import('@/views/assessment/bid-submitted/QualifiedBids'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/bid-reports',
    name: 'BidReports',
    component: () => import('@/views/assessment/bid-submitted/BidReports.vue'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/cash-in-lieu-reports',
    name: 'CashInLieuReports',
    component: () =>
      import('@/views/assessment/bid-submitted/CashInLieuReports'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/below-excess-reports',
    name: 'BelowExcessReports',
    component: () =>
      import('@/views/assessment/bid-submitted/BelowExcessReports'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/write-off-reports',
    name: 'WriteOffReports',
    component: () => import('@/views/assessment/bid-submitted/WriteOffReports'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/undocumented-reports',
    name: 'UndocumentedReports',
    component: () =>
      import('@/views/assessment/bid-submitted/UndocumentedReports'),
    meta: {
      middleware: [auth],
      roles: [
        'admin',
        'claimOfficer',
        'internalAssessor',
        'sectionHead',
        'externalAssessor',
        'technician',
      ],
    },
  },
  {
    path: '/bids-submitted/under-investigation-reports',
    name: 'UnderInvestigationReports',
    component: () =>
      import('@/views/assessment/bid-submitted/UnderInvestigationReports'),
    meta: {
      middleware: [auth],
      roles: [
        'admin',
        'claimOfficer',
        'internalAssessor',
        'sectionHead',
        'externalAssessor',
        'technician',
      ],
    },
  },
  {
    path: '/bids-submitted/declined-reports',
    name: 'DeclinedReports',
    component: () => import('@/views/assessment/bid-submitted/DeclinedReports'),
    meta: {
      middleware: [auth],
      roles: [
        'admin',
        'claimOfficer',
        'internalAssessor',
        'sectionHead',
        'externalAssessor',
        'technician',
      ],
    },
  },
  {
    path: '/bids-submitted/vehicle-checkout',
    name: 'VehicleCheckout',
    component: () => import('@/views/assessment/bid-submitted/VehicleCheckout'),
    meta: {
      middleware: [auth],
      roles: ['admin', 'internalAssessor', 'technician'],
    },
  },
  {
    path: '/bids-submitted/:bookingId',
    name: 'BidDetails',
    component: () => import('@/views/assessment/bid-submitted/BidDetails'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/bids-submitted/vehicle-checkout/:bookingId&:reg',
    name: 'CheckoutForm',
    component: () => import('@/views/assessment/bid-submitted/BidCheckout'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'admin'],
    },
  },
  {
    path: '/bids-submitted/garage/:bookingId',
    name: 'GarageBid',
    component: () => import('@/views/assessment/bid-submitted/GarageBid'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/garage-bid-approval/:id',
    name: 'ApproverBidForm',
    component: () => import('@/views/assessment/bid-submitted/ApproverBidForm'),
    meta: { noNavigation: true },
  },
  {
    path: '/garage-bid-form/:bookingId/:garageId',
    name: 'GarageBidForm',
    component: () => import('@/views/assessment/major-accident/GarageBidForm'),
    meta: { noNavigation: true },
  },
  {
    path: '/checklist',
    name: 'Checklist',
    component: () => import('@/components/assessment-center/booking/checklist'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/vehicle-inspection',
    name: 'VehicleInspection',
    component: () => import('@/views/assessment/VehicleInspection'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'technician', 'claimOfficer'],
    },
  },
  {
    path: '/supplementary-review',
    name: 'SupplementaryReview',
    component: () => import('@/views/assessment/SupplementaryReview'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'externalAssessor', 'admin'],
    },
  },
  {
    path: '/reinspection-view/:bookingId',
    name: 'ReinspectionView',
    component: () => import('@/views/assessment/ReinspectionView'),
    meta: {
      middleware: [auth],
      roles: ['internalAssessor', 'externalAssessor', 'admin'],
    },
  },
  // ? last route for pwa default
  {
    path: '/index.html', // or '*' this is for PWA
    beforeEnter: (_to, _from, next) => {
      next('/')
    },
  },
  {
    path: '/error',
    name: 'ServerError',
    component: () => import('../views/errors/Error50x'),
    meta: { noNavigation: true },
  },
  {
    path: '/no-internet',
    name: 'NoInternet',
    component: Connection,
    meta: { noNavigation: true },
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/errors/Error404'),
    meta: { noNavigation: true },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.meta.roles) {
    if (
      store.getters['auth/isAuthenticated'] &&
      viewPermissions(to.meta.roles)
    ) {
      next()
      return
    }
    next('/dashboard')
  }

  if (to.meta.middleware) {
    const middleware = to.meta.middleware

    const context = {
      to,
      from,
      next,
      store,
    }

    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1),
    })
  }

  return next()
})

export default router
