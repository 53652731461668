export default async function auth({ next, store }) {
  if (store.getters['auth/isAuthenticated']) {
    if (!store.getters['auth/authenticatedUser']) {
      await store.dispatch('auth/signOut').then(() => {
        next({ name: 'SignIn' })
      })
    }
    next()
    return
  }
  next('/')
}
