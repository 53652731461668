import axios from 'axios'

const data = {
  garageList: [],
  loadingTable: true,
  loading: false,
}

const mutations = {
  GARAGE_LISTING(state, payload) {
    state.garageList = payload.garageListResponse
    state.loadingTable = payload.loadingTable
  },
  GARAGE_VENDOR_ONBOARDING(state, payload) {
    state.loading = payload.loading
  },
  UPDATE_GARAGE_LISTING(state, payload) {
    const index = state.garageList.findIndex((i) => i.id === payload.id)
    state.garageList[index].rare_and_specialized = payload.rare_and_specialized
    state.garageList[index].cluster = payload.cluster
  },
}

const actions = {
  async inviteGarageVendor({ commit }, inviteData) {
    await axios
      .post('/motor-assessment/api/garages', inviteData)
      .then((res) => {
        commit('GARAGE_VENDOR_ONBOARDING', {
          loading: false,
        })
        commit(
          'SNACKBAR',
          {
            state: 'failed',
            message: `${res.data.msg}`,
            variant: 'success',
          },
          { root: true }
        )
      })
      .catch((err) => {
        commit('GARAGE_VENDOR_ONBOARDING', {
          loading: false,
        })
        this.showErrorMessage(commit, err.response.data.msg)
      })
  },

  updateGarageInfo({ commit }, formData) {
    axios
      .patch(`/motor-assessment/api/garages/${formData.id}`, formData)
      .then((res) => {
        commit(
          'SNACKBAR',
          {
            state: 'success',
            message: `${res.data.msg}`,
            variant: 'success',
          },
          { root: true }
        )
        commit('UPDATE_GARAGE_LISTING', res.data.data)
      })
      .catch((err) => {
        this.showErrorMessage(commit, err.response.data.msg)
      })
  },
  assignWinningBid({ commit }, formData) {
    axios
      .post(
        `/motor-assessment/api/tickets/external-assessor/qualify-bid/${formData.bookingId}/${formData.garageId}`,
        formData.payload
      )
      .then((res) => {
        commit(
          'SNACKBAR',
          {
            state: res.data.success ? 'success' : 'error',
            message: `${res.data.msg}`,
            variant: res.data.success ? 'success' : 'error',
          },
          { root: true }
        )
      })
      .catch((err) => {
        commit(
          'SNACKBAR',
          {
            state: 'error',
            message: err.message,
            variant: 'error',
          },
          { root: true }
        )
      })
  },
  showErrorMessage: function ({ commit }, message) {
    commit(
      'SNACKBAR',
      {
        state: 'error',
        message: `${message}`,
        variant: 'error',
      },
      { root: true }
    )
  },
}

const getters = {}

export default {
  namespaced: true,
  state: data,
  getters,
  actions,
  mutations,
}
