import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import AuthConfig from '@/utils/aws-exports'
import Auth from '@aws-amplify/auth'
import VueCookies from 'vue-cookies'
import Toasted from 'vue-toasted'

import '@/assets/css/tailwind.css'
import '@/assets/css/style.css'
import '@/assets/css/nprogress.css'
import './plugins/vuetify-money'

Vue.use(Toasted)

Vue.use(VueCookies)
Vue.$cookies.config('7d', null, null, true, 'Strict')

Auth.configure(AuthConfig)

import initialize from '@/utils/initialize'
import vuetify from './plugins/vuetify'
import helperMixins from '@/mixins/helperMixins'
import './registerServiceWorker'

Vue.config.productionTip = false
Vue.mixin(helperMixins)
initialize.init(router, store)

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
