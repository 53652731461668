//import cookie from 'vue-cookies'
import {  } from '@/utils/const'
const data = {
  bookings: [],
  biddingForm: {},
  supplementaryForm: {}
}

const getters = {
  bookings: (state) => state.bookings,
  biddingForm: (state) => state.biddingForm,
  supplementaryForm: (state) => state.supplementaryForm
}

const mutations = {
  updateBookings: (state, payload) => {
    state.bookings = payload
  },
  updateBiddingForm: (state, payload) => {
    state.biddingForm = { ...state.biddingForm, ...payload }
  },
  updateSupplementaryForm: (state, payload) => {
    state.supplementaryForm = payload 
    localStorage.setItem('bookingId', payload.bookingId)
  },
  clearBiddingForm: (state) => {
    state.biddingForm = {}
  },
}

const actions = {
  updateSupplementary(context,payload) {
    context.commit('updateSupplementaryForm',payload)
  }
}

export default {
  namespaced: true,
  state: data,
  getters,
  actions,
  mutations,
}
