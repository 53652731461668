export const USER_EMAIL = 'usr-email'
export const ACCESS_TOKEN = 'usr-token'
export const USER = 'usr'
export const ID_TOKEN = 'id-token'
export const VAT = 16
export const BIDDING_REPORT_CATEGORY = [
  'BIDS_SUBMITTED',
  'VEHICLES_BOOKED',
  'WRITE_OFF',
  'BELOW_EXCESS',
  'CASH_IN_LIEU',
  'UNDER_INVESTIGATIONS',
  'SUPPLEMENTARY_RECEIVED',
  'DECLINED',
  'APPROVED_BIDS',
  'UNDOCUMENTED',
  'CHECKED_OUT',
]
export const BIDDING_REPORT_GARAGE_CATEGORY = [
  'ALL',
  'VEHICLE_RELEASED',
  'REPAIR_IN_PROGRESS',
]
