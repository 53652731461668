import store from '@/store'

const viewPermissions = (roles = []) => {
  const user = store.getters['auth/authenticatedUser']
  return roles.length === 0
    ? true
    : [...roles, ...['admin']].includes(user['custom:role'])
}

export { viewPermissions }
